<template>
  <div class="auth-wrapper auth-v2">
    <div v-if="isLoadingPreloader" id="loading-bg">
      <div class="loading-logo">
        <img v-if="pageData.preloaderIcon" id="preloader-image" :src="pageData.preloaderIcon" alt="Logo">
        <p v-else id="preloader-text">
          Please Wait
        </p>
      </div>
      <div class="loading">
        <div class="effect-1 effects" />
        <div class="effect-2 effects" />
        <div class="effect-3 effects" />
      </div>
    </div>
    <b-row v-else class="auth-inner m-0">
      <!-- Brand logo-->
      <Header />
      <!-- /Brand logo-->
      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <CoverImage />
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="align-items-center auth-bg px-2 p-lg-5">
        <div class="row">
          <div class="mx-auto col-12 margin-top-icon-sm">
            <MediaIcon />
          </div>
          <div class="px-xl-2 mx-auto col-12 text-margin-top-sm">
            <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
              <!-- Welcome to OIS! 👋 -->
              Welcome to {{ pageData.welcomeMsg || "FEMS" }}
            </b-card-title>
            <b-card-text class="mb-2">
              Please sign-in to your account and start the adventure
            </b-card-text>

            <b-alert v-if="isNew" variant="success" class="p-1" show>
              <h3>Please Login with new password</h3>
            </b-alert>

            <!-- form -->
            <validation-observer ref="loginForm">
              <b-form class="auth-login-form mt-2" @submit.prevent="login">
                <!-- username -->
                <b-form-group label="User Name" label-for="login-userName">
                  <validation-provider #default="{ errors }" name="User Name" vid="userName" rules="required">
                    <b-form-input id="login-userName" v-model="userName" :state="errors.length > 0 ? false : null"
                      name="login-userName" placeholder="Enter User Name" type="text" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">Password</label>
<!--                    <b-link :to="{ name: 'auth-forgot-password' }">-->
<!--                      <small>Forgot Password?</small>-->
<!--                    </b-link>-->

                    <b-link :to="{ name: 'auth-forgot-username-password' }">
                      <small>Forgot Password?</small>
                    </b-link>
                  </div>
                  <validation-provider #default="{ errors }" name="Password" vid="password" rules="required">
                    <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                      <b-form-input id="login-password" v-model="password" :state="errors.length > 0 ? false : null"
                        class="form-control-merge" :type="passwordFieldType" name="login-password"
                        placeholder="Password" />
                      <b-input-group-append is-text>
                        <feather-icon class="cursor-pointer" :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility" />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- checkbox -->
                <b-form-group>
                  <b-form-checkbox id="remember-me" v-model="status" name="checkbox-1">
                    Remember Me
                  </b-form-checkbox>
                </b-form-group>

                <!-- submit buttons -->
                <b-button type="submit" variant="primary" block :disabled="isLoading">
                  Sign in
                </b-button>
              </b-form>
            </validation-observer>
          </div>
        </div>
      </b-col>
      <!-- /footer-->
      <b-col lg="12" class="mt-auto">
        <Footer />
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
} from 'bootstrap-vue'
import Header from '@/@core/components/landding-page/Header.vue'
import MediaIcon from '@/@core/components/landding-page/MediaIcon.vue'
import CoverImage from '@/@core/components/landding-page/CoverImage.vue'
import Footer from '@/@core/components/landding-page/Footer.vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
// import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { pageData, oisMediaLink } from '@/@fake-db/data/pages/system-settings'
// import { pageData, oisMediaLink, getLogo } from '@/@fake-db/data/pages/system-settings'

// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AuthService from '@/@service/api/Auth.service'
import CMSService from '@/@service/api/CMS.service'
import LocalStorageService from '@/@service/utils/LocalStorage.service'
import FemsToastrService from '@/@service/utils/FemsToastr.service'
import { userAbility } from '@/@service/utils/utils'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    BAlert,
    // VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    Header,
    MediaIcon,
    Footer,
    CoverImage,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userName: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      isNew: LocalStorageService.get('isNew') || 0,
      currentIp: '',
      getPageData: JSON.parse(LocalStorageService.get('pageData')) || '',
      pageData: {},
      oisMediaLink: [],
      // validation rules
      required,
      email,
      isLoading: false,
      isLoadingPreloader: true,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    // console.log(this.getPageData)
    if (this.getPageData) {
      this.isLoadingPreloader = false
      this.pageData = this.getPageData
    } else {
      this.onCmsDataRead()
    }
  },
  methods: {
    getIpAddress() {
      this.$http.get('https://jsonip.com').then(data => {
        // console.log('th data', data)
        this.currentIp = data?.ip
        // this.ipAddress = data
      })
    },
    login() {
      this.isLoading = true
      this.$refs.loginForm
        .validate()
        .then(success => {
          if (success) {
            AuthService.login({
              user_username: this.userName,
              password: this.password,
              ip_address: this.currentIp || '192.168.43.57',
              remember_me: this.status,
            })
              .then(resp => {
                const response = resp.data
                const responseData = response
                // console.log(responseData)
                if (!responseData.status && responseData?.validation_error) {
                  const validation = responseData?.validation_error

                  if (validation.user_username) {
                    this.$refs.loginForm.setErrors({
                      userName: [
                        validation.user_username
                          ? validation.user_username[0]
                          : null,
                      ],
                    })
                  }

                  if (validation.password) {
                    this.$refs.loginForm.setErrors({
                      password: [
                        validation.password ? validation.password[0] : null,
                      ],
                    })
                  }

                  FemsToastrService.error(responseData?.message)

                  this.isLoading = false
                  return
                }
                if (!responseData.status) {
                  FemsToastrService.error(responseData?.message)

                  this.isLoading = false
                  return
                }
                if (responseData.isPasswordReset) {
                  const data = response?.data
                  this.$refs.loginForm.setErrors({
                    userName: [responseData?.message],
                  })
                  FemsToastrService.error(responseData?.message)
                  this.isLoading = false

                  LocalStorageService.set(
                    'user_username',
                    JSON.stringify(data.user_username),
                  )
                  LocalStorageService.set(
                    'verificationData',
                    JSON.stringify(data),
                  )
                  this.$router.replace({ name: 'password.reset' })
                  return
                }
                if (responseData.isOtpVerification) {
                  const data = response?.data
                  this.$refs.loginForm.setErrors({
                    userName: [responseData?.message],
                  })
                  LocalStorageService.set(
                    'user_username',
                    JSON.stringify(data.user_username),
                  )
                  LocalStorageService.set(
                    'verificationData',
                    JSON.stringify(data),
                  )
                  FemsToastrService.success(
                    response.message || 'Already send OTP please enter the OTP.',
                  )

                  this.isLoading = false

                  this.$router.push({ name: 'otp-verify' })
                  return
                }

                if (responseData.isLogin && responseData.status) {
                  LocalStorageService.clear()
                  useJwt.setToken(responseData?.access_token)
                  useJwt.setRefreshToken(responseData?.access_token)
                  LocalStorageService.set('isRememberMe', this.status)
                  this.userName.startsWith('200') ? LocalStorageService.set('isOisEmployer',  true) : LocalStorageService.set('isOisEmployer', false)
                  LocalStorageService.set('institution_id', resp.data?.institution_id)
                  this.onRequestRefreshToken()
                }
              })
              .catch(error => {
                this.isLoading = false

                let errorMessage = 'Something went wrong'
                if (error?.errors?.throttle) {
                  errorMessage = error?.errors?.throttle[0]
                } else if (error?.errors?.user_username) {
                  errorMessage = error?.errors?.user_username[0]
                }
                this.$refs.loginForm.setErrors({ userName: [error?.message] })
                FemsToastrService.error(errorMessage)
              })
          } else {
            this.isLoading = false
          }
        })
        .catch(() => {
          this.isLoading = false
        })
    },

    onRequestRefreshToken() {
      AuthService.refreshTokenGenerate()
        .then(resp => {
          const res = resp.data
          if (res.status) {
            useJwt.setRefreshToken(res.refresh_token)
            LocalStorageService.set('refreshToken', res.refresh_token)
            this.afterLoginCMSData()
            return
          }

          LocalStorageService.clear()
          this.$router.push({ name: 'auth-login' })
        })
        .catch(error => {
          LocalStorageService.clear()
          this.isLoading = false
          FemsToastrService.error(error.message)
          this.$router.push({ name: 'auth-login' })
        })
    },

    userDataManipulation() {
      const token = LocalStorageService.get('accessToken')
      AuthService.userInfo()
        .then(resp => {
          const response = resp.data
          if (response.status) {
            const res = response.data
            let userInfo = {}
            let isAdmin = 0
            if (res?.employee_information) {
              userInfo = res.employee_information
              isAdmin = res.employee_information.is_admin
            } else if (res?.guardian_information) {
              userInfo = res.guardian_information
            } else if (res?.student_information) {
              userInfo = res?.student_information
            } else if (res?.alumni_information) {
              userInfo = res?.alumni_information
            }
            userInfo.ability = userAbility(isAdmin)

            if (
              res?.employee_information
              && [2001140000, 2001150001, 2001150051, 2001200021].includes(
                userInfo.employee_id,
              )
            ) {
              userInfo.ability.push({
                action: 'customResource',
                subject: 'customResource',
              })
            }

            if (
              res?.employee_information
              && [2002180072].includes(userInfo.employee_id)
            ) {
              const acl = [
                {
                  action: 'accounting',
                  subject: 'accounting',
                }, {
                  action: 'payment-collection',
                  subject: 'payment-collection',
                }, {
                  action: 'student-concessions',
                  subject: 'student-concessions',
                }, {
                  action: 'vouchers',
                  subject: 'vouchers',
                }, {
                  action: 'fintech',
                  subject: 'fintech',
                }, {
                  action: 'payment-cycle-config',
                  subject: 'payment-cycle-config',
                }, {
                  action: 'group-invoice',
                  subject: 'group-invoice',
                }, {
                  action: 'due-report',
                  subject: 'due-report',
                }, {
                  action: 'student-ledger',
                  subject: 'student-ledger',
                },
              ]

              userInfo.ability.push(...acl)
            }

            userInfo.role = 'admin'
            LocalStorageService.set('userData', JSON.stringify(userInfo))
            LocalStorageService.set('pageData', JSON.stringify(this.pageData))
            this.$ability.update(userInfo?.ability)
            window.location.href = `${process.env.VUE_APP_CORE_BASE_URL}login/check_login_fems_admin/?t=${token}`
          } else {
            this.$refs.loginForm.setErrors({ userName: [response?.message] })
            FemsToastrService.error(response?.message)
            this.isLoading = false
            LocalStorageService.clear()
          }
          this.isLoading = false
        })
        .catch(error => {
          this.$refs.loginForm.setErrors({ userName: [error?.message] })
          FemsToastrService.error(error?.message)
          this.isLoading = false
          LocalStorageService.clear()
        })
    },

    onCmsDataRead() {
      const favicon = document.getElementById('favicon')
      const title = document.getElementById('title')
      const preloaderText = document.getElementById('preloader-text')

      CMSService.get()
        .then(resp => {
          const res = resp.data
          if (!res.status) {
            favicon.href = ''
            title.innerHTML = 'Something went wrong!'
            preloaderText.innerHTML = 'Something went wrong!, please contact with respective dept'
            this.isLoadingPreloader = true
            FemsToastrService.error(
              'Something went wrong!, please contact with respective dept',
            )

            return
          }

          // console.log(resData)
          this.isLoadingPreloader = false
          const resData = res.data
          if (resData.slug.toLowerCase() === 'ois') {
            this.pageData = resData
            this.pageData.name = resData.name
            this.pageData.logo = pageData.oisLogo
            this.pageData.slug = 'ois'
            this.pageData.welcomeMsg = 'OIS'
            this.pageData.poweredByLogo = pageData.oisfavIcon
            this.pageData.moneybagLogo = pageData.moneybagLogo
            this.pageData.coverImage = pageData.oisCoverImage
            this.pageData.mediaLinks = oisMediaLink
            this.pageData.knowladeBasedLink = 'https://oxfordintl.freshdesk.com/support/home'
            this.pageData.helpDeskLink = 'https://oxfordintl.freshdesk.com/support/home'
            this.pageData.faqLink = 'https://oxfordintl.freshdesk.com/support/solutions/folders/43000017580'
            this.pageData.videoTutorialLink = 'https://youtube.com/c/OxfordInternationalSchoolDhaka'
            favicon.href = pageData.oisfavIcon
            this.pageData.favicon = pageData.oisfavIcon
            // console.log(pageData.oisfavIcon)
          } else {
            this.pageData = resData || pageData
            this.pageData.welcomeMsg = 'FEMS'
            this.pageData.logo = pageData.femsLogo
            this.pageData.poweredByLogo = pageData.femsLogo
            this.pageData.moneybagLogo = pageData.moneybagLogo
            this.pageData.coverImage = pageData.femsCoverImage
            this.pageData.mediaLinks = pageData.mediaLinks
            this.pageData.favicon = pageData.femsLogo
            favicon.href = pageData.femsLogo
          }
          title.innerHTML = this.pageData.welcomeMsg
          LocalStorageService.set('pageData', JSON.stringify(this.pageData))
          // const preloaderImage = document.getElementById('preloader-image')
          // preloaderImage.src = this.pageData.logo
        })
        .catch(() => {
          favicon.href = ''
          title.innerHTML = 'Something went wrong!'
          preloaderText.innerHTML = 'Something went wrong!, please contact with respective dept'
          this.isLoadingPreloader = true
          FemsToastrService.error(
            'The system is not ready to run , please make sure the system configuration',
          )
        })
    },
    afterLoginCMSData() {
      CMSService.getData()
        .then(resp => {
          const res = resp.data
          const resData = res.data
          const favicon = document.getElementById('favicon')
          if (res.status) {
            this.userDataManipulation()
            if (resData.slug.toLowerCase() === 'ois') return
            this.pageData = resData
            this.pageData.poweredByLogo = pageData.femsLogo
            this.pageData.powered_by = resData.powered_by

            // eslint-disable-next-line no-nested-ternary
            this.pageData.favicon = Number(resData.id) === 2
              ? pageData.apbnpscFavIcon
              : (Number(resData.id) === 20 ? pageData.bdBank : pageData.femsLogo)
            // eslint-disable-next-line no-nested-ternary
            favicon.href = Number(resData.id) === 2
              ? pageData.apbnpscFavIcon
              : (Number(resData.id) === 20 ? pageData.bdBank : pageData.femsLogo)
            this.pageData.welcomeMsg = resData.name
            LocalStorageService.set('pageData', JSON.stringify(this.pageData))
            return
          }

          this.$refs.loginForm.setErrors({ userName: [res?.message] })
          FemsToastrService.error(res?.message)
          this.isLoading = false
          LocalStorageService.clear()
        })
        .catch(error => {
          this.$refs.loginForm.setErrors({ userName: [error?.message] })
          FemsToastrService.error(error?.message)
          this.isLoading = false
          LocalStorageService.clear()
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
