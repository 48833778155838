/* eslint-disable global-require */
export const pageData = {
  oisLogo: require('@/assets/images/logo/ois-logo.png'),
  oisfavIcon: require('@/assets/images/logo/ois-avatar.png'),
  apbnLogo: require('@/assets/images/logo/apbn-logo.png'),
  femsLogo: require('@/assets/images/logo/fems-icon.png'),
  bdBank: require('@/assets/images/logo/bd-school.jpg'),
  apbnpscFavIcon: require('@/assets/images/logo/apbnnpsc.png'),
  femsCoverImage: require('@/assets/images/logo/cover/FEMS.svg'),
  oisCoverImage: require('@/assets/images/logo/cover/OIS.svg'),
  preloaderIcon: require('@/assets/images/logo/preloader.png'),
  moneybagLogo: require('@/assets/images/logo/moneybag-logo.png'),
  poweredByLogo: require('@/assets/images/logo/fems-icon.png'),
  mediaLinks: [
    {
      id: '1',
      name: 'facebook',
      link: 'https://www.facebook.com/fingerprintems/',
      icon: require('@/assets/images/logo/facebook.svg'),
    },
    {
      id: '2',
      name: 'youtube',
      link: 'https://www.youtube.com/channel/UCtGtDoTqgiYsH-qQCXdtqoA',
      icon: require('@/assets/images/logo/youtube.svg'),
    },
    {
      id: '3',
      name: 'linkedin',
      link: 'https://www.linkedin.com/company/fitl/',
      icon: require('@/assets/images/logo/linkedin.svg'),
    },
  ],
}
export const oisMediaLink = [
  {
    id: '1',
    name: 'facebook',
    link: 'https://www.facebook.com/oisbd1987',
    icon: require('@/assets/images/logo/facebook.svg'),
  },
  {
    id: '2',
    name: 'youtube',
    link: 'https://youtube.com/c/OxfordInternationalSchoolDhaka',
    icon: require('@/assets/images/logo/youtube.svg'),
  },
  {
    id: '3',
    name: 'linkedin',
    link: 'https://www.linkedin.com/company/oxford-international-school',
    icon: require('@/assets/images/logo/linkedin.svg'),
  },
]

export const getLogo = institutionId => `http://core.fems.education/content/institution_logo/${institutionId}_50_50.png`
